<template>
  <div class="p-grid p-jc-center">
    <div class="p-col-12 p-md-4">
      <Fieldset legend="ลืมรหัสผ่าน">
        <form @submit.prevent="handleForgot" id="validity">
          <div class="p-field p-grid">
            <div class="p-col-12 p-fluid">
              <span class="p-input-icon-left">
                <i class="pi pi-user" />
                <InputText
                  type="text"
                  class="p-inputtext-lg"
                  v-model="forgot.userName"
                  :class="{ 'p-invalid': !forgot.userName }"
                  placeholder="ชื่อเข้าใช้งาน"
                  :required="true"
                />
              </span>
            </div>
            <div class="p-col-12">
              <InlineMessage v-if="!forgot.userName">ห้ามว่าง</InlineMessage>
            </div>
          </div>
          <div class="p-field p-grid">
            <div class="p-col-12 p-fluid">
              <span class="p-input-icon-left">
                <i class="pi pi-send" />
                <InputText
                  type="email"
                  class="p-inputtext-lg"
                  v-model="forgot.secureEmail"
                  :class="{ 'p-invalid': !forgot.secureEmail }"
                  :feedback="false"
                  placeholder="อีเมล"
                  :required="true"
                />
              </span>
            </div>
            <div class="p-col-12">
              <InlineMessage v-if="!forgot.secureEmail">ห้ามว่าง</InlineMessage>
            </div>
          </div>
          <div class="p-d-flex p-jc-center">
            <Button
              label="ส่ง"
              :icon="isLoading"
              iconPos="left"
              type="submit"
              :disabled="isDisabled"
              class="p-button-success"
            />
            <Button
              label="ไม่ ทำรายการ"
              icon="pi pi-arrow-left"
              iconPos="left"
              class="p-button-link"
              @click="() => $router.back(-1)"
            />
          </div>
        </form>
      </Fieldset>
    </div>
  </div>

  <Dialog
    header="สถานะการทำรายการ กู้คืนรหัสผ่าน"
    v-model:visible="dialog.isOpen"
    :class="['p-dialog-responsive']"
    :style="{ width: '50vw' }"
  >
    <p>
      ระบบได้ทำการส่งเมล ลิ้งเปลี่ยนรหัสผ่าน
      คุณสามารถเข้าไปที่กล่องข้อความขาเข้าของเมล์ที่ได้ลงทะเบียนไว้
      เพื่อทำการกดลิ้งค์เปลี่ยนรหัสผ่านได้ทันที อีเมลที่ลงทะเบียนไว้คือ
      <strong>{{ forgot.secureEmail }}</strong>
    </p>
    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-exit"
        type="button"
        class="p-button-link"
        @click="() => (dialog.isOpen = false)"
      />
    </template>
  </Dialog>
</template>

<script>
import { initCustomValidity } from '@/HTMLElement'
export default {
  layout: 'Public',
  data() {
    return {
      forgot: {
        userName: '',
        secureEmail: ''
      },
      loader: {
        isLoad: false
      },
      dialog: {
        isOpen: false,
        message: ''
      }
    }
  },
  computed: {
    isLoading() {
      return this.loader.isLoad ? 'pi pi-spin pi-spinner' : 'pi pi-send'
    },
    isDisabled() {
      return this.loader.isLoad
    }
  },
  mounted() {
    initCustomValidity('validity')
  },
  methods: {
    /*
      gmail
      poolsawatapin@gmail.com
    */
    async handleForgot() {
      const { userName, secureEmail } = this.forgot
      if (!userName || userName == '' || !secureEmail || secureEmail == '') {
        this.$toast.add({
          severity: 'error',
          summary: 'สถานะการทำรายการ',
          detail: 'กรุณากรอก ชื่อเข้าใช้งาน และอีเมล ก่อนยืนยันการทำรายการ',
          life: 3000
        })
      } else {
        this.loader.isLoad = true
        const { status, code, data, invalidCode } = await this.$store.dispatch(
          'user/sendMailForgotPassword',
          this.forgot
        )
        this.loader.isLoad = false
        console.log('status, code, data ::== ', status, code, data)
        if (code == 200) {
          this.dialog.isOpen = true
        } else {
          /*
           * 101 = profile not found
           * 102 = secure pass invalid
           * 103 = old pass invalid
           */
          let message =
            'การส่งคำขอ เปลี่ยนรหัสผ่านของคุณ ไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ'
          if ('101' == invalidCode) {
            message = 'ไม่พบข้อมูลของคุณในระบบ'
          } else if ('102' == invalidCode) {
            message = 'รหัสผ่านที่ใช้ยืนยันทำรายการ ไม่ถูกต้อง'
          } else if ('103' == invalidCode) {
            message = 'รหัสผ่านเก่า ไม่ถูกต้อง'
          } else if ('104' == invalidCode) {
            message = 'รหัสผ่านไม่ ไม่ตรงกัน กรุณาตรวจสอบ'
          }
          this.$toast.add({
            severity: 'error',
            summary: 'สถานะการทำรายการ',
            detail: message,
            life: 3000
          })
        }
      }
    }
  }
}
</script>

<style>
</style>